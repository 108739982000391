<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="mt-4" novalidate @submit.prevent="handleSubmit(onSubmit)">
      <ValidationProvider vid="username" name="username" rules="required" v-slot="{ errors }">
        <div class="form-group">
          <label for="usernameInput">Account number</label>
          <input type="text" :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
                 id="usernameInput"
                 v-model="user.username" placeholder="Enter Username" required>
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <ValidationProvider vid="password" name="Password" rules="required" v-slot="{ errors }">
        <div class="form-group">
          <label for="passwordInput">Password</label>
          <router-link to="/auth/password-reset1" class="float-right">
            Forgot password?
          </router-link>
          <input type="password"  :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
                 id="passwordInput"
                 v-model="user.password" placeholder="Password" required>
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <div class="d-inline-block w-100">
        <div class="custom-control custom-checkbox d-inline-block mt-2 pt-1">
          <input type="checkbox" class="custom-control-input" :id="formType">
          <label class="custom-control-label" :for="formType">Remember Me</label>
        </div>
        <button type="submit" class="btn btn-primary float-right">Sign in</button>
      </div>
      <div class="sign-info">
          <span class="dark-color d-inline-block line-height-2">
            Don't have an account?
            <router-link to="/auth/sign-up1" class="iq-waves-effect pr-4">
            Reach us
            </router-link>
          </span>
        <!-- <social-login-form></social-login-form> -->
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
// import SocialLoginForm from './SocialLoginForm'
import { mapGetters } from 'vuex'

export default {
  name: 'SignIn1Form',
  components: {
    // SocialLoginForm
  },
  props: ['formType', 'username', 'password'],
  data: () => ({
    user: {
      username: '',
      password: ''
    }
  }),
  mounted () {
    this.user.username = this.$props.username
    this.user.password = this.$props.password
  },
  computed: {
    ...mapGetters({
      stateUsers: 'Setting/usersState',
      logToken: 'Setting/logToken'
    })
  },
  methods: {
    onSubmit () {
      if (this.formType === 'custom') {
        this.customLogin()
      }
    },
    customLogin () {
      const loginDetails = {
        account_number: this.user.username,
        password: this.user.password
      }
      const axios = require('axios')

      const config = {
        method: 'post',
        url: process.env.VUE_APP_SITE_URL + '/api/api/login?api_token=false',
        data: loginDetails
      }

      axios.request(config)
        .then((response) => {
          if (response.data.code === 0) {
            const userData = response.data
            localStorage.setItem('loginData', JSON.stringify(userData))
            localStorage.setItem('login', this.user.username)
            this.$store.dispatch('Setting/authUserAction', {
              auth: true,
              authType: 'customLogin',
              user: {
                id: userData.id,
                name: userData.firstname,
                mobileNo: userData.phone_number,
                email: userData.email,
                profileImage: null
              }
            })
            // this.$store.commit('Setting/addUserTokenAction', userData.api_token)
            this.$store.dispatch('Setting/addUserTokenAction', userData.api_token)
            this.$router.push({ name: 'dashboard.home-1' })
          } else {
            alert(response.data.result)
            this.$router.push({ path: '/auth/sign-in1' })
          }
        })
        .catch((error) => {
          console.log('error', error)
        })
    }
  }
}
</script>
